<template>
	<div>
		<b-card title="Article List">
			<b-row class="mb-2">
				<b-col cols="12" md="6">
					<router-link
						to="/create-article"
						variant="primary"
						class="btn waves-effect waves-float waves-light btn-primary"
						v-if="checkPermission('add article')"
					>
						Add Article
					</router-link>
				</b-col>
			</b-row>
			<b-table 
				striped 
				hover 
				:items="article" 
				:fields="fields"
				:busy="isLoading"
				show-empty
				responsive
			>
				<template #table-busy>
					<div class="text-center text-secondary my-2">
						<b-spinner class="align-middle"></b-spinner>
						<strong>Loading...</strong>
					</div>
				</template>
				
				<template v-slot:cell(no)="{ index }">
					{{ (currentPage - 1) * 10 + index + 1 }}
				</template>

				<template v-slot:cell(thumbnail)="{ item }">
					<img 
						class="article__image" 
						:src="item.thumbnail" 
						alt=""
						v-if="item.thumbnail"
					/>
					<div v-else>-</div>
				</template>

				<template v-slot:cell(image)="{ item }">
					<img 
						class="article__image" 
						:src="item.image" 
						alt=""
						v-if="item.image"
					/>
					<div v-else>-</div>
				</template>

				<template v-slot:cell(actions)="{ item }">
					<div class="d-flex" style="gap:12px;">
						<router-link
							:to="'/edit-article/'+ item.uuid"
							v-if="checkPermission('update article')"
						>
							<b-badge 
								variant="warning"
								class="badge-glow"
							>
								<feather-icon
									:id="`invoice-row-${item.uuid}-send-icon`"
									icon="EditIcon"
									class="cursor-pointer"
									size="16"
									v-ripple.400="'rgba(113, 102, 240, 0.15)'"
									v-b-tooltip.hover.bottom="'Edit Data'"
								/>
							</b-badge>
						</router-link>

						<!-- <b-badge 
							variant="primary"
							class="badge-glow"
						>
							<feather-icon
								:id="`invoice-row-${item.uuid}-send-icon`"
								icon="AlertCircleIcon"
								class="cursor-pointer"
								size="20"
								v-ripple.400="'rgba(113, 102, 240, 0.15)'"
								v-b-tooltip.hover.bottom="'Detail Data'"
								@click="createWorkingType(item.uuid)"
							/>
						</b-badge> -->
						
						<b-badge 
							variant="danger"
							class="badge-glow"
						>
							<feather-icon
								:id="`invoice-row-${item.uuid}-preview-icon`"
								icon="TrashIcon"
								size="16"
								v-ripple.400="'rgba(113, 102, 240, 0.15)'"
								v-b-tooltip.hover.bottom="'Delete Data'"
								v-if="checkPermission('delete article')"
								@click="deleteItem(item.uuid)"
							/>
						</b-badge>
					</div>
				</template>
			</b-table>
			<div
				v-if="result.total > 0"
				class="m-1"
			>
				<div class="row">
					<div class="col mb-1">
						<small>Showing {{ result.from }} to {{ result.to }} from {{ result.total }}</small>
					</div>
					<div class="col">
						<pagination
							:data="result"
							:limit="4"
							align="right"
							@pagination-change-page="getData"
						/>
					</div>
				</div>
			</div>
			<b-modal
				id="form-detail-article" 
				centered
				title="Insight Information"
				size="xl"
				hide-footer
			>
				<b-row>
					<b-col cols="12" md="8">
						<b-card>
							<b-row>
								<b-col cols="12" md="4" class="mb-3 mb-md-0">
									<img :src="formDetail.image" alt="" class="w-100">
								</b-col>
								<b-col cols="12" md="8">
									<div 
										style="max-height: 500px; overflow-y:scroll;" 
										v-html="formDetail.content">
									</div>
								</b-col>
							</b-row>
						</b-card>
					</b-col>
					<b-col cols="12" md="4">
						<b-row>
							<b-col cols="12" md="6">
								<b-card class="text-center">
									<div class="d-flex align-items-center justify-content-center" style="gap: 10px;">
										<feather-icon
											icon="EyeIcon"
											class="cursor-pointer"
											size="16"
										/>
										<div class="font-weight-bold">View Count</div>
									</div>
									<h1 class="mb-0 display-4">{{ formDetail.view_count }}</h1>
								</b-card>
							</b-col>
							<b-col cols="12" md="6">
								<b-card class="text-center">
									<div class="d-flex align-items-center justify-content-center" style="gap: 10px;">
										<feather-icon
											icon="ShareIcon"
											class="cursor-pointer"
											size="16"
										/>
										<div class="font-weight-bold">Share Count</div>
									</div>
									<h1 class="mb-0 display-4">{{ formDetail.share_count }}</h1>
								</b-card>
							</b-col>
							<b-col cols="12">
								<b-card>
									<div class="d-flex align-items-center mb-1" style="gap: 10px;">
										<feather-icon
											icon="MessageSquareIcon"
											class="cursor-pointer"
											size="16"
										/>
										<h5 class="font-weight-bold mb-0">Feedback</h5>
									</div>
									<div style="max-height: 332px; overflow-y:scroll;" v-if="feedbacks != ''">
										<div class="py-2 border-bottom" v-for="item in feedbacks" :key="item.uuid">
											<p class="font-weight-bold">
												{{ item.content }}
											</p>
											<div class="d-flex justify-content-between align-items-center">
												<div class="text-secondary" style="font-size:12px;">
													{{ item.customer.name }}
													{{ item.customer.email }}
												</div>
												<div class="text-secondary" style="font-size:12px;">
													{{ moment(item.created_at).format('ll') }}
												</div>
											</div>
										</div>
									</div>
									<div class="text-center" v-else>
										<p>Tidak ada data feedback yang ditampilkan</p>
									</div>
								</b-card>
							</b-col>
						</b-row>
					</b-col>
				</b-row>
			</b-modal>
		</b-card>
	</div>
</template>

<script>
import { 
	BCard,
	BCardText,
	BTable,
	BPagination,
	BBadge,
	BButton,
	BSpinner,
	BRow,
	BCol,
	VBTooltip,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { checkPermission, successNotification, errorNotification } from '@/auth/utils'
import moment from 'moment'
import _ from 'lodash'
export default {
	components: {
		BCard,
		BCardText,
		BTable,
		BPagination,
		BButton,
		BSpinner,
		BBadge,
		BRow,
		BCol
	},
	directives: {
	'b-tooltip': VBTooltip,
		Ripple,
	},
	watch: {
		filter: {
			handler: _.debounce(function () {
				this.getData()
			}, 300),
			deep: true,
		},
	},
	
	setup(props) {
		return {
			checkPermission,
		}
	},
	data() {
		return {
			moment,
			currentPage: 1,
			result: {},
			isLoading: false,
			fields: ['no', 'title', 'thumbnail', 'image', 'actions'],
			article: [],
			feedbacks: [],
			filter: {
				keyword: ''
			},
			formDetail: Object,
			detailId: null,
		}
	},

	computed: {
		rows() {
			return this.article.length
		}
	},

	methods: {
		getData(page) { //page = 1
			this.isLoading = true
			const queryParams = this.filter
			queryParams.per_page = 10
			queryParams.page = page
			this.$http.get('admin/articles', {
				params: queryParams,
			})
				.then(response => {
				this.result = response.data.data
				this.article = response.data.data.data
				this.isLoading = false
				this.currentPage = response.data.data.current_page
				})
		},
		createWorkingType(uuid) {
            this.detailId = uuid
            this.getDataByUuid(uuid)
			this.$bvModal.show('form-detail-article')
		},
		getDataByUuid(uuid) {
            this.$http.get('/admin/articles/' + uuid)
            .then(response => {
				let detailArticle = response.data.data
                this.formDetail = {
                    image		: detailArticle.image,
                    content		: detailArticle.content,
					view_count 	: detailArticle.view_count,
					share_count : detailArticle.share_count
                }
			})
			this.$http.get(`/admin/articles/${uuid}/feedbacks`)
			.then(response => {
				this.feedbacks = response.data.data.data
			})
        },
		deleteItem(uuid) {
			this.$swal({
				title: 'Apakah Anda yakin?',
				icon: 'info',
				text: 'Anda akan menghapus Article ini. Data yang sudah terhapus tidak dapat dipulihkan.',
				type: 'warning',
				showCancelButton: true,
				customClass: {
					confirmButton: 'btn btn-primary',
					cancelButton: 'btn btn-outline-danger ml-1',
				},
				confirmButtonText: 'Ya, hapus data ini',
				cancelButtonText: 'Batal',
				buttonsStyling: false
			}).then(async result =>{
				if(result.value) {
					await this.$http.delete('admin/articles/' + uuid)
					this.getData(this.currentPage)
					successNotification(this, 'Success', 'Article berhasil dihapus!')
				}
			}).catch(error => {
				errorNotification(this, 'Oops!', 'Ada Kendala Teknis')
			})
		}
	},

	created() {
		this.getData();
	}
}
</script>

<style>
	.article__image {
		height: 50px;
	}
</style>
